<template>
  <div class="p-12 bg-white">
    <div class="max-w-6xl text-center m-auto">
      <p class="text-lg text-blue-50 font-bold pb-4 sm:pb-6">
        As seen on
      </p>

      <div :class="themeClass">
        <div
          v-for="(item, itemKey) in outlets"
          :key="itemKey"
          :class="{'flex shrink max-w-[5rem] xs:max-w-max': theme === 'compact'}"
        >
          <img
            v-bind="item"
            class="m-auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'AtomsMediaOutlets'
})

const props = defineProps({
  mediaOutlets: {
    type: Array,
    required: true
  },

  imageLazyLoad: {
    type: Boolean,
    default: false
  },

  theme: {
    type: String,
    default: 'default'
  }
})

const outlets = computed(() => {
  const list = props.mediaOutlets
  const lazyLoad = props.imageLazyLoad

  if (_isEmpty(list)) {
    return []
  }

  return list.map(item => {
    const urlArr = item.url.split('/')
    const alt = urlArr[urlArr.length - 1]
      .replace(/.(svg|png|jpg|jpeg|webp|gif)$/g, '')
      .replace(/(-|_)/gm, ' ')

    return {
      ...((item?.url && { src: item.url }) || {}),
      ...((alt && { alt }) || {}),
      ...((item?.width && { width: item.width }) || {}),
      ...((item?.height && { height: item.height }) || {}),
      ...((lazyLoad && { loading: 'lazy' }) || {})
    }
  })
})

const themeClass = computed(() => {
  return getKey(props.theme, {
    default: 'grid lg:grid-cols-8 grid-cols-2 gap-8',
    // compact: 'grid lg:grid-cols-7 sm:grid-cols-5 grid-cols-5 gap-3 xs:gap-4 sm:gap-5 md:gap-8  place-items-center justify-content-center'
    compact: 'flex flex-wrap justify-center gap-4 md:gap-8 md:gap-x-12'
  })
})
</script>
